import axios from "axios";
import { ACCESS_TOKEN, APP_ID, ORG_ID, REFRESH_TOKEN } from "../constant/constant";
import { LocalStorageUtils } from "../utils/local-storage-utils";

export const API_DOMAIN = process.env.REACT_APP_API_BASE_URL;

export const API_PATH = (orgId: string,appId: string) => ({
  requestOtp: `${orgId}/${appId}/auth/requestOtp`,
  login: `${orgId}/${appId}/auth/login`,
  logout: `${orgId}/${appId}/auth/logout`,
  refreshToken: `${orgId}/${appId}/auth/refresh`,
});

const Axios = axios.create({
  baseURL: API_DOMAIN,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let token = LocalStorageUtils.getStorage(ACCESS_TOKEN);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error?.response) {
    }
    const originalRequest = error.config;
    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = LocalStorageUtils.getStorage(REFRESH_TOKEN);
        if (refreshToken) {
          originalRequest.headers["Authorization"] = `Bearer ${refreshToken}`;
        }
        const response = await axios.post(API_DOMAIN + API_PATH(LocalStorageUtils.getStorage(ORG_ID) as string, LocalStorageUtils.getStorage(APP_ID) as string).refreshToken);
        LocalStorageUtils.setStorage(ACCESS_TOKEN, response.data.accessToken);
        LocalStorageUtils.setStorage(REFRESH_TOKEN, response.data.refreshToken);
        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
        return axios(originalRequest);
      } catch (error) {
        // Handle refresh token error or redirect to login
        LocalStorageUtils.removeStorage(ACCESS_TOKEN);
        LocalStorageUtils.removeStorage(REFRESH_TOKEN);
        window.location.href = "/#/login";
      }
    }
    return Promise.reject(error);
  }
);

export default Axios;
