import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { isAuth } from '../../utils/function';

export const ProtectedRoute: React.FC = ()=>{
  return isAuth() ? <Outlet /> : <Navigate to='/login' />
}

export const PublicRoute: React.FC = ()=>{
  return isAuth() ? <Navigate to='/' /> : <Outlet />
}